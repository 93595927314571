<template>
	<div class="from-panel" ref="panel" v-loading="loading">
		<!-- <div class="from-title">
			<span style="color: #606266;"></span>
			<div>
				<el-button
					size="mini"
					type="primary"
					icon="el-icon-plus"
					@click="newTemplate()"
				>
					新建流程
				</el-button>
				<el-button size="mini" icon="el-icon-plus" @click="addGroup">
					新建分组
				</el-button>
			</div>
		</div> -->
		<draggable
			:list="groups"
			group="group"
			handle=".el-icon-rank"
			filter=".undrag"
			@start="groupsSort = true"
			:options="{
				animation: 300,
				sort: true,
				scroll: true,
				chosenClass: 'choose'
			}"
			@end="sortGroup"
		>
			<div
				:class="{
					'group-info': true,
					undrag: group.id === 0 || group.id === undefined
				}"
				v-for="(group, gidx) in groups"
				:key="gidx"
			>
				<div class="group-info-title">
					<span>{{ group.name }}</span>
					<span>({{ group.templates.length }})</span>
					<i class="el-icon-rank" title="长按拖动可对分组排序"></i>
					<!-- <div v-if="!(group.id === 0 || group.id === undefined)">
						<el-dropdown>
							<el-button type="text" icon="el-icon-setting">
								编辑分组
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item
									icon="el-icon-edit-outline"
									@click.native="editGroup(group)"
								>
									修改名称
								</el-dropdown-item>
								<el-dropdown-item
									icon="el-icon-delete"
									@click.native="deleteGroup(group)"
								>
									删除分组
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div> -->
				</div>
				<div style="width: 100%; min-height: 25px">
					<div
						:class="{
							'group-info-item': true,
							undrag: item.status == 0
						}"
						v-for="(item, index) in group.templates"
						:key="index"
					>
						<div>
							<span>{{ item.name }}</span>
						</div>
						<div class="desp">{{ item.remark }}</div>
						<div>
							<span>最后更新时间：{{ item.updateTime }}</span>
						</div>
						<div>
							<el-button
								type="text"
								icon="el-icon-edit-outline"
								size="mini"
								@click="editTemplate(item.id)"
							>
								编辑
							</el-button>
							<!-- <el-button
								type="text"
								:icon="
									item.status == 1
										? 'el-icon-check'
										: 'el-icon-close'
								"
								size="mini"
								@click="changeTemplate(item)"
							>
								{{ item.status == 0 ? '启用' : '停用' }}
							</el-button>
							<el-button
								type="text"
								icon="el-icon-delete"
								size="mini"
								@click="deleteTemplate(item)"
								v-if="item.status == 0"
							>
								删除
							</el-button>
							<el-popover
								placement="left"
								trigger="click"
								width="400"
								style="margin-left: 10px"
								@show="moveSelect === null"
								v-else
							>
								<el-radio-group
									v-model="moveSelect"
									size="mini"
								>
									<el-radio
										:label="g.id"
										border
										v-for="g in groups"
										:key="g.id"
										v-show="g.id > 1"
										:disabled="g.id === group.id"
										style="margin: 10px"
									>
										{{ g.name }}
									</el-radio>
								</el-radio-group>
								<div style="text-align: right; margin: 0">
									<el-button
										type="primary"
										size="mini"
										@click="moveTemplate(item.id)"
									>
										提交
									</el-button>
								</div>
								<el-button
									slot="reference"
									type="text"
									icon="el-icon-s-promotion"
									size="mini"
								>
									移动
								</el-button>
							</el-popover> -->
						</div>
					</div>
				</div>
				<!-- <div
					style="text-align: center"
					v-if="
						group.templates === undefined ||
						group.templates.length === 0
					"
				>
					<el-button
						style="padding-top: 0"
						type="text"
						icon="el-icon-plus"
						@click="newTemplate(group.id)"
					>
						创建流程
					</el-button>
				</div> -->
			</div>
		</draggable>
	</div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
	name: 'Groups',
	components: { draggable },
	data() {
		return {
			loading: false,
			moveSelect: '',
			visible: false,
			groupsSort: false,
			groups: []
		}
	},
	mounted() {
		this.listGroupsWithTemplate()
	},
	methods: {
		async listGroupsWithTemplate() {
			const _this = this
			try {
				this.loading = true
				let groups = (await this.$api.flowable.listGroupsWithTemplate()) || []
				this.groups = groups.map(item => {
					let templates = item.templates || []
					item.templates = templates.map(tem => {
						tem.updateTime = _this.$format.dateFormat(tem.updateTime)
						return tem
					})
					return item
				})
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		addGroup() {
			this.$prompt('请输入要添加的组名', '新的分组名', {
				confirmButtonText: '提交',
				cancelButtonText: '取消',
				inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
				inputErrorMessage: '分组名不能为空且长度小于30',
				inputPlaceholder: '请输入分组名'
			}).then(({ value }) => {
				this.$api.flowable
					.updateGroup({ name: value }, 'post')
					.then(() => {
						this.listGroupsWithTemplate()
					})
			})
		},
		editGroup(group) {
			this.$prompt('请输入新的组名', '修改分组名', {
				confirmButtonText: '提交',
				cancelButtonText: '取消',
				inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9\\-]{1,30}$/,
				inputErrorMessage: '分组名不能为空且长度小于30',
				inputPlaceholder: '请输入分组名',
				inputValue: group.name
			}).then(({ value }) => {
				this.$api.flowable
					.updateGroup({ id: group.id, name: value }, 'put')
					.then(() => {
						this.listGroupsWithTemplate()
					})
			})
		},
		deleteGroup(group) {
			this.$confirm(
				'删除分组并不会删除表单，表单将会被转移到 “其他” 分组，确定要删除分组 ' +
					group.name +
					'?',
				'提示',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}
			).then(() => {
				this.$api.flowable.deleteGroup({ id: group.id }).then(() => {
					this.listGroupsWithTemplate()
				})
			})
		},
		sortGroup() {
			this.groupsSort = false
			let ids = this.groups.map(item => item.id)
			this.$api.flowable.sortGroup(ids).then(() => {
				this.$message.success('操作成功')
				this.listGroupsWithTemplates()
			})
		},
		newTemplate(groupId) {
			if (groupId == undefined) {
				groupId = ''
			}
			this.$router.push(`/design?groupId=${groupId}`)
		},
		editTemplate(templateId) {
			this.$router.push(`/design?templateId=${templateId}`
			)
		},
		deleteTemplate(template) {
			this.$confirm(
				`您确定要删除表单 <span style="font-weight: bold">${template.name}</span>> 吗，删除后无法恢复，是否继续？`,
				'提示',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					dangerouslyUseHTMLString: true
				}
			).then(() => {
				this.$api.flowable
					.deleteTemplate({ id: template.id })
					.then(() => {
						this.listGroupsWithTemplate()
					})
			})
		},
		changeTemplate(template) {
			let tip =
				template.status == 0 ? ' 是否启用流程？' : ' 是否禁用流程?'
			this.$confirm(
				`<span style="font-weight: bold">${template.name}</span>` + tip,
				'提示',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					dangerouslyUseHTMLString: true
				}
			).then(() => {
				this.$api.flowable
					.changeTemplateStatus({
						id: template.id,
						status: template.status == 1 ? 0 : 1
					})
					.then(() => {
						this.listGroupsWithTemplate()
					})
			})
		},
		async moveTemplate(templateId) {
			if (this.moveSelect === null || this.moveSelect === '') {
				this.$message.error('请选择分组')
				return
			}
			await this.$api.flowable.moveTemplate({
				id: templateId,
				groupId: this.moveSelect
			})
			this.moveSelect = null
			this.listGroupsWithTemplate()
		}
	}
}
</script>

<style lang="less" scoped>
body {
	background: #ffffff !important;
}

.undrag {
	background: #ebecee !important;
}

.from-panel {
	/deep/ .from-title {
		display: flex;
		justify-content: space-between;
		padding-top: 10px;
		div {
			display: flex;
			justify-content: flex-end;
			.el-button {
				border-radius: 15px;
			}
		}
	}
}

.choose {
	background: #e9ebee;
}

.group-info {
	margin: 15px 0;
	padding: 5px 0px;
	border-radius: 10px;
	box-shadow: 1px 1px 10px 0 #d2d2d2;
	background-color: #fff;
	&:hover {
		box-shadow: 1px 1px 12px 0 #b3b3b3;
	}

	.group-info-title {
		padding: 5px 20px;
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid #d3d3d3;

		.el-icon-rank {
			display: none;
			cursor: move;
		}

		&:hover {
			.el-icon-rank {
				display: inline-block;
			}
		}

		div {
			display: inline-block;
			float: right;
		}

		span:first-child {
			margin-right: 5px;
			font-size: 15px;
			font-weight: bold;
		}

		span:nth-child(2) {
			color: #656565;
			font-size: small;
			margin-right: 10px;
		}

		/deep/ .el-button {
			color: #404040;
			margin-left: 20px;

			&:hover {
				color: #2b2b2b;
			}
		}
	}

	.group-info-item:nth-child(1) {
		border-top: none !important;
	}

	.group-info-item {
		color: #3e3e3e;
		font-size: small;
		padding: 10px 0;
		margin: 0 20px;
		height: 40px;
		position: relative;
		line-height: 40px;
		border-top: 1px solid #d3d3d3;

		div {
			display: inline-block;
		}

		i {
			border-radius: 10px;
			padding: 7px;
			font-size: 20px;
			color: #ffffff;
			margin-right: 10px;
		}

		div:nth-child(1) {
			float: left;
		}

		div:nth-child(2) {
			position: absolute;
			color: #7a7a7a;
			font-size: 12px;
			left: 200px;
			max-width: 300px;
			overflow: hidden;
		}

		div:nth-child(3) {
			position: absolute;
			right: 30%;
		}

		div:nth-child(4) {
			float: right;
		}
	}
}

@media screen and (max-width: 1000px) {
	.desp {
		display: none !important;
	}
}
</style>
