var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"panel",staticClass:"from-panel"},[_c('draggable',{attrs:{"list":_vm.groups,"group":"group","handle":".el-icon-rank","filter":".undrag","options":{
			animation: 300,
			sort: true,
			scroll: true,
			chosenClass: 'choose'
		}},on:{"start":function($event){_vm.groupsSort = true},"end":_vm.sortGroup}},_vm._l((_vm.groups),function(group,gidx){return _c('div',{key:gidx,class:{
				'group-info': true,
				undrag: group.id === 0 || group.id === undefined
			}},[_c('div',{staticClass:"group-info-title"},[_c('span',[_vm._v(_vm._s(group.name))]),_c('span',[_vm._v("("+_vm._s(group.templates.length)+")")]),_c('i',{staticClass:"el-icon-rank",attrs:{"title":"长按拖动可对分组排序"}})]),_c('div',{staticStyle:{"width":"100%","min-height":"25px"}},_vm._l((group.templates),function(item,index){return _c('div',{key:index,class:{
						'group-info-item': true,
						undrag: item.status == 0
					}},[_c('div',[_c('span',[_vm._v(_vm._s(item.name))])]),_c('div',{staticClass:"desp"},[_vm._v(_vm._s(item.remark))]),_c('div',[_c('span',[_vm._v("最后更新时间："+_vm._s(item.updateTime))])]),_c('div',[_c('el-button',{attrs:{"type":"text","icon":"el-icon-edit-outline","size":"mini"},on:{"click":function($event){return _vm.editTemplate(item.id)}}},[_vm._v(" 编辑 ")])],1)])}),0)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }